/*----------------------------------------------*\
  style
\*----------------------------------------------*/

.editor-template{
  *{
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
  }

  font-family: @body-font-family;
  font-weight: @body-font-weight;
  color: @body-color;
  line-height: @body-line-height;
  letter-spacing: @body-letter-spacing;
  background: @body-background;

  // 新テンプレートだったら
  body[class*="is--detail"] &{
    font-size: 1.266666rem; // 9.5pt相当
  }

  // 黒背景だったら
  body.body-black &{
    color: @white;
  }

  // スマホだったら
  @media @xsmall{
    font-size: @base-font-size;
  }

  &+.editor-template{
    margin-top: 22px;
  }
  // hybsリセット
  div.center div.element_detail &,
  div.entry & { //スマホ新テンプレ
    h1,h2,h3,h4,h5,h6{
      margin: @headings-margin;
      background-image: none !important;
      border: none;
      line-height: @headings-line-height;
      padding: 0;
      font-weight: @headings-font-weight;
      color:@headings-color !important;
      @media @xsmall{
        margin: @headings-margin-xsmall;
      }
      &.border-bottom-title{
        padding-bottom: .5em;
        margin: @headings-margin-large;
        border-bottom: 1px solid @heading-border-color-light;
      }
      &.border-left-title{
        padding-top: .3em;
        padding-bottom: .3em;
        padding-left: .5em;
        margin: @headings-margin-large;
        border-left: 5px solid @heading-border-color;
      }
      body.body-black &{
        color: @white !important;
      }
    }
    // Heading Font Sizes
    h1 {
      .font-size(@h1-font-size);
    }
    h2 {
      .font-size(@h2-font-size);
    }
    h3 {
      .font-size(@h3-font-size);
    }
    h4 {
      .font-size(@h4-font-size);
    }
    h5 {
      .font-size(@h5-font-size);
    }
    h6 {
      .font-size(@h6-font-size);
    }
  }
  div.entry &{ //スマホ新テンプレ
    p{
      margin: @paragraph-margin;
    }
  }


  /*
   * Normalize
   */

  @import 'editor_template/lib/normalize.less';


  /*
   * Base
   */

  @import 'editor_template/base/global.less';
  @import 'editor_template/base/body.less';
  @import 'editor_template/base/colors.less';
  @import 'editor_template/base/font.less';
  @import 'editor_template/base/link.less';
  @import 'editor_template/base/image.less';
  @import 'editor_template/base/breakpoints.less';
  @import 'editor_template/base/media-queries.less';
  @import 'editor_template/base/print.less';


  /*
   * Typography
   */

  @import 'editor_template/typography/headings.less';
  @import 'editor_template/typography/paragraph.less';
  @import 'editor_template/typography/lead.less';
  @import 'editor_template/typography/strong.less';
  @import 'editor_template/typography/emphasis.less';
  @import 'editor_template/typography/hr.less';
  @import 'editor_template/typography/blockquote.less';
  @import 'editor_template/typography/pullout.less';
  @import 'editor_template/typography/list.less';
  @import 'editor_template/typography/definition-list.less';
  @import 'editor_template/typography/code.less';
  @import 'editor_template/typography/pre.less';
  @import 'editor_template/typography/kbd.less';


  /*
   * Grid
   */

  @import 'editor_template/grid/container.less';
  @import 'editor_template/grid/row.less';
  @import 'editor_template/grid/gutter.less';
  @import 'editor_template/grid/column.less';
  @import 'editor_template/grid/offset.less';
  @import 'editor_template/grid/push.less';
  @import 'editor_template/grid/pull.less';


  /*
   * Form
   */

  @import 'editor_template/form/label.less';
  @import 'editor_template/form/fieldset.less';
  @import 'editor_template/form/form-message.less';
  @import 'editor_template/form/input.less';
  @import 'editor_template/form/input-indicators.less';
  @import 'editor_template/form/input-sizes.less';
  @import 'editor_template/form/input-group.less';
  @import 'editor_template/form/input-group-sizes.less';
  @import 'editor_template/form/control.less';
  @import 'editor_template/form/select.less';
  @import 'editor_template/form/select-sizes.less';


  /*
   * Elements
   */

  @import 'editor_template/elements/button.less';
  @import 'editor_template/elements/button-styles.less';
  @import 'editor_template/elements/button-indicators.less';
  @import 'editor_template/elements/button-sizes.less';
  @import 'editor_template/elements/button-group.less';
  @import 'editor_template/elements/figure.less';
  @import 'editor_template/elements/media.less';
  @import 'editor_template/elements/nav.less';
  @import 'editor_template/elements/table.less';


  /*
   * Utilities
   */

  @import 'editor_template/utilities/utility-classes.less';
  @import 'editor_template/utilities/visibility.less';
  @import 'editor_template/utilities/display.less';
  @import 'editor_template/utilities/positioning.less';
  @import 'editor_template/utilities/flex.less';
  @import 'editor_template/utilities/float.less';
  @import 'editor_template/utilities/max-width.less';
  @import 'editor_template/utilities/margin.less';
  @import 'editor_template/utilities/padding.less';
  @import 'editor_template/utilities/font-family.less';
  @import 'editor_template/utilities/font-size.less';
  @import 'editor_template/utilities/text.less';
  @import 'editor_template/utilities/text-color.less';
  @import 'editor_template/utilities/background.less';


  /*
   * SVG
   */
  @import 'editor_template/lib/svg.less';


  /*
   * Mixins
   */

  @import 'editor_template/mixins/mixins.less';
  @import 'editor_template/mixins/button.less';
  @import 'editor_template/mixins/input.less';
  @import 'editor_template/mixins/select.less';
  @import 'editor_template/mixins/font.less';
  @import 'editor_template/mixins/border-radius.less';
  @import 'editor_template/mixins/background.less';
  @import 'editor_template/mixins/placeholder.less';
  @import 'editor_template/mixins/arrows.less';
  @import 'editor_template/mixins/transitions.less';
  @import 'editor_template/mixins/transforms.less';
  @import 'editor_template/mixins/animations.less';
  @import 'editor_template/mixins/filters.less';
  @import 'editor_template/mixins/flexbox.less';


  /*
   * Accessibility
   */

  @import 'editor_template/accessibility/screen-reader.less';
}