/*
 * Definitions
 */

// Nav
@nav-list-margin: 0;
@nav-item-margin: 10px 0px;

// Nav Inline
@nav-inline-item-margin: 0px 10px;


/*
 * Nav
 */

nav,
.nav {
	
	ul {
		margin: @nav-list-margin;
		list-style: none;
		
		li {
			margin: @nav-item-margin;
		}
	}
}

.nav-inline {
	
	ul {
		
		li {
			display: inline-block;
			margin: @nav-inline-item-margin;
		}
	}
}