/*
 * Padding
 */

// Padding
.padding-xlarge {
	padding: @padding-xlarge;
}

.padding-large {
	padding: @padding-large;
}

.padding-medium {
	padding: @padding-medium;
}

.padding-small {
	padding: @padding-small;
}

.padding-xsmall {
	padding: @padding-xsmall;
}

// Padding Top
.padding-top-xlarge {
	padding-top: @padding-xlarge;
}

.padding-top-large {
	padding-top: @padding-large;
}

.padding-top-medium {
	padding-top: @padding-medium;
}

.padding-top-small {
	padding-top: @padding-small;
}

.padding-top-xsmall {
	padding-top: @padding-xsmall;
}

// Padding Bottom
.padding-bottom-xlarge {
	padding-bottom: @padding-xlarge;
}

.padding-bottom-large {
	padding-bottom: @padding-large;
}

.padding-bottom-medium {
	padding-bottom: @padding-medium;
}

.padding-bottom-small {
	padding-bottom: @padding-small;
}

.padding-bottom-xsmall {
	padding-bottom: @padding-xsmall;
}

// Padding Left
.padding-left-xlarge {
	padding-left: @padding-xlarge;
}

.padding-left-large {
	padding-left: @padding-large;
}

.padding-left-medium {
	padding-left: @padding-medium;
}

.padding-left-small {
	padding-left: @padding-small;
}

.padding-left-xsmall {
	padding-left: @padding-xsmall;
}

// Padding Right
.padding-right-xlarge {
	padding-right: @padding-xlarge;
}

.padding-right-large {
	padding-right: @padding-large;
}

.padding-right-medium {
	padding-right: @padding-medium;
}

.padding-right-small {
	padding-right: @padding-small;
}

.padding-right-xsmall {
	padding-right: @padding-xsmall;
}