/*
 * Definitions
 */

// Fonts
@font-path: '../fonts';
@sans-serif-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック体", "Yu Gothic", YuGothic, "游ゴシック Medium", "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;;
@serif-font-family: Georgia, "Times New Roman", Times, serif;
@monospace-font-family: Monaco, Menlo, Consolas, "Courier New", monospace;

// Generic Font Families
@thin-font-family: @sans-serif-font-family;
@regular-font-family: @sans-serif-font-family;
@medium-font-family: @sans-serif-font-family;
@semibold-font-family: @sans-serif-font-family;
@bold-font-family: @sans-serif-font-family;

// Generic Font Weights
@thin-font-weight: 300;
@regular-font-weight: 400;
@medium-font-weight: 500;
@semibold-font-weight: 600;
@bold-font-weight: 700;

// Base Font Size
@base-font-size: 9.5pt;
@base-font-size-xlarge: @base-font-size;
@base-font-size-large: @base-font-size;
@base-font-size-medium: @base-font-size;
@base-font-size-small: @base-font-size;
@base-font-size-xsmall: @base-font-size;

// Font Sizes
@font-size-xlarge: 2.4;
@font-size-large: 1.3;
@font-size-medium: 1.02632;
@font-size-small: 1;
@font-size-xsmall: .6;

// Line height
@line-height-xlarge: 2;
@line-height-large: 1.7;
@line-height-medium: 1.7;
@line-height-small: 1.2;
@line-height-xsmall: 1;


/*
 * Font Size
 */

html {
	font-size: @base-font-size;

	@media @xlarge {
		font-size: @base-font-size-xlarge;
	}
	
	@media @large {
		font-size: @base-font-size-large;
	}

	@media @medium {
		font-size: @base-font-size-medium;
	}
	
	@media @small {
		font-size: @base-font-size-small;
	}

	@media @xsmall {
		font-size: @base-font-size-xsmall;
	}
}