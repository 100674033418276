/*
 * Definitions
 */

// Label
@label-margin: 15px 0px;
@label-font-family: @semibold-font-family;
@label-font-weight: @semibold-font-weight;
@label-font-size: @font-size-small;
@label-color: @dark;
@label-line-height: @line-height-medium;


/*
 * Label
 */

//Label
label {
	display: block;
	margin: @label-margin;
	font-family: @label-font-family;
	font-weight: @label-font-weight;
	.font-size(@label-font-size);
	color: @label-color;
	line-height: @label-line-height;
}