/*
 * Definitions
 */

// Kbd
@kbd-margin: 0px 5px;
@kbd-padding: 4px;
@kbd-font-family: @code-font-family;
@kbd-font-weight: @code-font-weight;
@kbd-font-size: @code-font-size;
@kbd-color: @code-color;
@kbd-background: @pre-background;
@kbd-border: @pre-border;


/*
 * Kbd
 */

kbd {
	margin: @kbd-margin;
	padding: @kbd-padding;
	font-family: @kbd-font-family;
	font-weight: @kbd-font-weight;
	.font-size(@kbd-font-size);
	color: @kbd-color;
	background: @kbd-background;
	border: @kbd-border;
}