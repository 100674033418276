/*
 * Margin
 */

// Margin
.margin-xlarge {
	margin: @margin-xlarge;
}

.margin-large {
	margin: @margin-large;
}

.margin-medium {
	margin: @margin-medium;
}

.margin-small {
	margin: @margin-small;
}

.margin-xsmall {
	margin: @margin-xsmall;
}

// Margin Top
.margin-top-xlarge {
	margin-top: @margin-xlarge;
}

.margin-top-large {
	margin-top: @margin-large;
}

.margin-top-medium {
	margin-top: @margin-medium;
}

.margin-top-small {
	margin-top: @margin-small;
}

.margin-top-xsmall {
	margin-top: @margin-xsmall;
}

// Margin Bottom
&,
div.entry &{
  .margin-bottom-xlarge {
  	margin-bottom: @margin-xlarge;
  }

  .margin-bottom-large {
  	margin-bottom: @margin-large;
  }

  .margin-bottom-medium {
  	margin-bottom: @margin-medium;
  }

  .margin-bottom-small {
  	margin-bottom: @margin-small;
  }

  .margin-bottom-xsmall {
  	margin-bottom: @margin-xsmall;
  }
}

// Margin Left
.margin-left-xlarge {
	margin-left: @margin-xlarge;
}

.margin-left-large {
	margin-left: @margin-large;
}

.margin-left-medium {
	margin-left: @margin-medium;
}

.margin-left-small {
	margin-left: @margin-small;
}

.margin-left-xsmall {
	margin-left: @margin-xsmall;
}

// Margin Right
.margin-right-xlarge {
	margin-right: @margin-xlarge;
}

.margin-right-large {
	margin-right: @margin-large;
}

.margin-right-medium {
	margin-right: @margin-medium;
}

.margin-right-small {
	margin-right: @margin-small;
}

.margin-right-xsmall {
	margin-right: @margin-xsmall;
}