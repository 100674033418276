/*
 * Definitions
 */

// Gutter
@gutter: 15px;
@gutter-xlarge: @gutter;
@gutter-large: @gutter;
@gutter-medium: @gutter;
@gutter-small: @gutter / 2;
@gutter-xsmall: @gutter / 2;


/*
 * Gutter
 */

.no-gutter {
	padding: 0 !important;
}

.gutter-top {
	padding-top: @gutter + 3px !important;
  margin-top: 0 !important;
}

.gutter-bottom {
	padding-bottom: @gutter + 3px !important;
  margin-bottom: 0 !important;
}

.gutter-left {
	padding-left: @gutter !important;
}

.gutter-right {
	padding-right: @gutter !important;
}

@media @xlarge {

	.no-gutter-xlarge {
		padding: 0 !important;
	}
	
	.gutter-top {
		padding-top: @gutter-xlarge + 3px !important;
    margin-top: 0 !important;
	}
	
	.gutter-bottom {
		padding-bottom: @gutter-xlarge + 3px !important;
    margin-bottom: 0 !important;
	}
	
	.gutter-left {
		padding-left: @gutter-xlarge !important;
	}
	
	.gutter-right {
		padding-right: @gutter-xlarge !important;
	}
}

@media @large {

	.no-gutter-large {
		padding: 0 !important;
	}
	
	.gutter-top {
		padding-top: @gutter-large + 3px !important;
    margin-top: 0 !important;
	}
	
	.gutter-bottom {
		padding-bottom: @gutter-large + 3px !important;
    margin-bottom: 0 !important;
	}
	
	.gutter-left {
		padding-left: @gutter-large !important;
	}
	
	.gutter-right {
		padding-right: @gutter-large !important;
	}
}

@media @medium {

	.no-gutter-medium {
		padding: 0 !important;
	}
	
	.gutter-top {
		padding-top: @gutter-medium + 3px !important;
    margin-top: 0 !important;
	}
	
	.gutter-bottom {
		padding-bottom: @gutter-medium + 3px !important;
    margin-bottom: 0 !important;
	}
	
	.gutter-left {
		padding-left: @gutter-medium !important;
	}
	
	.gutter-right {
		padding-right: @gutter-medium !important;
	}
}

@media @small {

	.no-gutter-small {
		padding: 0 !important;
	}
	
	.gutter-top {
		padding-top: @gutter-medium + 3px !important;
    margin-top: 0 !important;
	}
	
	.gutter-bottom {
		padding-bottom: @gutter-medium + 3px !important;
    margin-bottom: 0 !important;
	}
	
	.gutter-left {
		padding-left: @gutter-medium !important;
	}
	
	.gutter-right {
		padding-right: @gutter-medium !important;
	}
}

@media @xsmall {
	
	.no-gutter-xsmall {
		padding: 0 !important;
	}
	
	.gutter-top {
		padding-top: @gutter-medium + 3px !important;
    margin-top: 0 !important;
	}
	
	.gutter-bottom {
		padding-bottom: @gutter-medium + 3px !important;
    margin-bottom: 0 !important;
	}
	
	.gutter-left {
		padding-left: @gutter-xsmall !important;
	}
	
	.gutter-right {
		padding-right: @gutter-xsmall !important;
	}
}