/*
 * Definitions
 */

// Table
@table-background: none;
@table-border-radius: @border-radius;

// Table Caption
@table-caption-margin: 5px 10px;
@table-caption-font-family: @bold-font-family;
@table-caption-font-weight: @bold-font-weight;
@table-caption-font-size: @font-size-medium;
@table-caption-color: @body-color;
@table-caption-text-align: left;

// Table Head
@table-head-padding: 15px 25px;
@table-head-padding-large: 25px;
@table-head-padding-small: 15px;
@table-head-padding-xsmall: 10px 15px;
@table-head-font-family: @regular-font-family;
@table-head-font-weight: @regular-font-weight;
@table-head-font-size: @paragraph-font-size;
@table-head-text-align: left;
@table-head-text-transform: none;
@table-head-color: @body-color;

// Table Body
@table-cell-padding: 15px 25px;
@table-cell-padding-large: 25px;
@table-cell-padding-small: 15px;
@table-cell-padding-xsmall: 10px 15px;
@table-cell-font-family: @regular-font-family;
@table-cell-font-weight: @regular-font-weight;
@table-cell-font-size: @paragraph-font-size;
@table-cell-color: @body-color;
@table-cell-line-height: @line-height-medium;
@table-cell-vertical-align: middle;

// Table Border
@table-border: solid @light-grey;
@table-border-dark: solid @grey;
@table-border-width: 1px;

// Table Block
@table-block-head-background: @light;

// Table Reponsive
@table-responsive-breakpoint: @small-down;
@table-responsive-row-margin: 20px 0px;
@table-responsive-cell-padding: 10px;
@table-responsive-border: solid @light;
@table-responsive-border-width: 1px;


/*
 * Table
 */

table {
	background: @table-background;
	border-radius: @table-border-radius;
	
	caption {
		margin: @table-caption-margin;
		font-family: @table-caption-font-family;
		font-weight: @table-caption-font-weight;
		.font-size(@table-caption-font-size);
		color: @table-caption-color;
		text-align: @table-caption-text-align;
	}
}

// Table Head
th {
	padding: @table-head-padding;
	font-family: @table-head-font-family;
	font-weight: @table-head-font-weight;
	.font-size(@table-head-font-size);
	color: @table-head-color;
	text-align: @table-head-text-align;
	text-transform: @table-head-text-transform;
  @media @xsmall {
    padding: @table-head-padding-xsmall;
  }
}

// Table Cell
td {
	padding: @table-cell-padding;
	font-family: @table-cell-font-family;
	font-weight: @table-cell-font-weight;
	.font-size(@table-cell-font-size);
	color: @table-cell-color;
	line-height: @table-cell-line-height;
	vertical-align: @table-cell-vertical-align;
  @media @xsmall {
    padding: @table-cell-padding-xsmall;
  }
}
body.body-black &{
  th,
  td{
    color: @white;
  }
}

// Table Bordered
.table-bordered {

	th,
	td {
		border: @table-border;
		border-width: @table-border-width;
	}
}

.table-horizontal-bordered{

  th,
  td{
    border: @table-border;
    border-width: @table-border-width 0;
  }
  &.table-block th{
    background: none;
  }
  th{
    font-weight: 600;
  }

}

.table-extended{

  th{
    padding: @table-head-padding-large;
    @media @xsmall {
      padding: @table-head-padding-small;
    }
  }
  td{
    padding: @table-cell-padding-large;
    @media @xsmall {
      padding: @table-cell-padding-small;
    }
  }

}

// Table Block
.table-block {
	width: 100%;

	th {
		background: @table-block-head-background;
    body.body-black &{
      color: @table-head-color;
    }
	}
}

// Table White
.table-white{
  th,
  td{
    background: none;
  }
  th{
    font-weight: 600;
    body.body-black &{
      color: @white;
    }
  }
}

// Table Outline
.table-outline{
  border: @table-border-dark;
  border-width: @table-border-width;
}

// Table Responsive
.table-responsive {

	@media @table-responsive-breakpoint {

		// Force table to not be like tables anymore
		table, thead, tbody, th, td, tr {
			display: block;
		}

		// Hide table headers (but not display: none; for accessibility)
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		tr {
			margin: @table-responsive-row-margin;
			border: @table-responsive-border;
			border-width: @table-responsive-border-width;
		}

		td {
			// Behave  like a "row"
			position: relative;
			padding: @table-responsive-cell-padding;
			border: none;
		}
	}
}