/*
 * Definitions
 */

// Strong
@strong-font-family: @bold-font-family;
@strong-font-weight: @bold-font-weight;


/*
 * Strong
 */

strong {
	font-family: @strong-font-family;
	font-weight: @strong-font-weight;
}