/*
 * Row
 */
 
.row {
	margin-left: -@gutter;
	margin-right: -@gutter;
	.clearfix();

	&.no-gutter {
		margin-left: 0;
		margin-right: 0;
	}
  &.small-gutter{
    margin-left: -@gutter-small;
    margin-right: -@gutter-small;
  }
  &.before-after{
    [class*='column-']+[class*='column-'] figure{
      position: relative;
      &:before{
        display: block;
        content: ' ';
        left: -@gutter * 2;
        height: 100%;
        width: @gutter * 2;
        position: absolute;
        background: url(@svg-arrow-r) no-repeat center;
        @media @xsmall{
          display: none;
        }
      }
    }
  }

	@media @xlarge {
		margin-left: -@gutter-xlarge;
		margin-right: -@gutter-xlarge;

		&.no-gutter-xlarge {
			margin-left: 0;
			margin-right: 0;
		}
	}

	@media @large {
		margin-left: -@gutter-large;
		margin-right: -@gutter-large;

		&.no-gutter-large {
			margin-left: 0;
			margin-right: 0;
		}
	}

	@media @medium {
		margin-left: -@gutter-medium;
		margin-right: -@gutter-medium;

		&.no-gutter-medium {
			margin-left: 0;
			margin-right: 0;
		}
	}
	
	@media @small {
		margin-left: -@gutter-medium;
		margin-right: -@gutter-medium;

		&.no-gutter-small {
			margin-left: 0;
			margin-right: 0;
		}
	}

	@media @xsmall {
    // margin-left: -@gutter-xsmall;
    // margin-right: -@gutter-xsmall;
		margin-left: 0;
		margin-right: 0;

		&.no-gutter-xsmall {
			margin-left: 0;
			margin-right: 0;
		}
    &.small-gutter{
      margin-left: 0;
      margin-right: 0;
    }
	}
}