/*
 * Definitions
 */

// Paragraph
@paragraph-margin: 15px 0px;
@paragraph-font-family: @regular-font-family;
@paragraph-font-weight: @regular-font-weight;
@paragraph-font-size: @font-size-medium;
@paragraph-color: @body-color;
@paragraph-line-height: @line-height-medium;
@paragraph-justify: justify;


/*
 * Paragraph
 */

p {
	margin: @paragraph-margin;
	font-family: @paragraph-font-family;
	font-weight: @paragraph-font-weight;
	.font-size(@paragraph-font-size);
	color: @paragraph-color;
	line-height: @paragraph-line-height;
  text-align: @paragraph-justify;
}

body.body-black & p{
  color: @white;
}