/*
 * Column Offset Classes
 */

.column-offset-1 {
	margin-left: @column-1;
}

.column-offset-1-2,
.column-offset-2-4,
.column-offset-3-6,
.column-offset-4-8,
.column-offset-5-10,
.column-offset-6-12  {
	margin-left: @column-1-2;
}

.column-offset-1-3,
.column-offset-2-6,
.column-offset-3-9,
.column-offset-4-12 {
	margin-left: @column-1-3;
}

.column-offset-2-3,
.column-offset-4-6,
.column-offset-6-9,
.column-offset-8-12 {
	margin-left: @column-1-3 * 2;
}

.column-offset-1-4,
.column-offset-2-8,
.column-offset-3-12 {
	margin-left: @column-1-4;
}

.column-offset-3-4,
.column-offset-6-8,
.column-offset-9-12 {
	margin-left: @column-1-4 * 3;
}

.column-offset-1-5,
.column-offset-2-10 {
	margin-left: @column-1-5;
}

.column-offset-2-5,
.column-offset-4-10 {
	margin-left: @column-1-5 * 2;
}

.column-offset-3-5,
.column-offset-6-10 {
	margin-left: @column-1-5 * 3;
}

.column-offset-4-5,
.column-offset-8-10 {
	margin-left: @column-1-5 * 4;
}

.column-offset-1-6,
.column-offset-2-12 {
	margin-left: @column-1-6;
}

.column-offset-5-6 {
	margin-left: @column-1-6 * 5;
}

.column-offset-1-7 {
	margin-left: @column-1-7;
}

.column-offset-2-7 {
	margin-left: @column-1-7 * 2;
}

.column-offset-3-7 {
	margin-left: @column-1-7 * 3;
}

.column-offset-4-7 {
	margin-left: @column-1-7 * 4;
}

.column-offset-5-7 {
	margin-left: @column-1-7 * 5;
}

.column-offset-6-7 {
	margin-left: @column-1-7 * 6;
}

.column-offset-1-8 {
	margin-left: @column-1-8;
}

.column-offset-3-8 {
	margin-left: @column-1-8 * 3;
}

.column-offset-5-8 {
	margin-left: @column-1-8 * 5;
}

.column-offset-7-8 {
	margin-left: @column-1-8 * 7;
}

.column-offset-1-9 {
	margin-left: @column-1-9;
}

.column-offset-2-9 {
	margin-left: @column-1-9 * 2;
}

.column-offset-4-9 {
	margin-left: @column-1-9 * 4;
}

.column-offset-5-9 {
	margin-left: @column-1-9 * 5;
}

.column-offset-7-9 {
	margin-left: @column-1-9 * 7;
}

.column-offset-8-9 {
	margin-left: @column-1-9 * 8;
}

.column-offset-1-10 {
	margin-left: @column-1-10;
}

.column-offset-3-10 {
	margin-left: @column-1-10 * 3;
}

.column-offset-7-10 {
	margin-left: @column-1-10 * 7;
}

.column-offset-9-10 {
	margin-left: @column-1-10 * 9;
}

.column-offset-1-11 {
	margin-left: @column-1-11;
}

.column-offset-2-11 {
	margin-left: @column-1-11 * 2;
}

.column-offset-3-11 {
	margin-left: @column-1-11 * 3;
}

.column-offset-4-11 {
	margin-left: @column-1-11 * 4;
}

.column-offset-5-11 {
	margin-left: @column-1-11 * 5;
}

.column-offset-6-11 {
	margin-left: @column-1-11 * 6;
}

.column-offset-7-11 {
	margin-left: @column-1-11 * 7;
}

.column-offset-8-11 {
	margin-left: @column-1-11 * 8;
}

.column-offset-9-11 {
	margin-left: @column-1-11 * 9;
}

.column-offset-10-11 {
	margin-left: @column-1-11 * 10;
}

.column-offset-1-12 {
	margin-left: @column-1-12;
}

.column-offset-5-12 {
	margin-left: @column-1-12 * 5;
}

.column-offset-7-12 {
	margin-left: @column-1-12 * 7;
}

.column-offset-10-12 {
	margin-left: @column-1-12 * 10;
}

.column-offset-11-12 {
	margin-left: @column-1-12 * 11;
}


/*
 * Column Offset Extra Large Classes
 */
 
@media @xlarge {
	
	//Column Offset Reset
	.column-offset-xlarge-reset {
		margin-left: 0;
	}

	.column-offset-xlarge-1 {
		margin-left: @column-1;
	}
	
	.column-offset-xlarge-1-2,
	.column-offset-xlarge-2-4,
	.column-offset-xlarge-3-6,
	.column-offset-xlarge-4-8,
	.column-offset-xlarge-5-10,
	.column-offset-xlarge-6-12  {
		margin-left: @column-1-2;
	}
	
	.column-offset-xlarge-1-3,
	.column-offset-xlarge-2-6,
	.column-offset-xlarge-3-9,
	.column-offset-xlarge-4-12 {
		margin-left: @column-1-3;
	}
	
	.column-offset-xlarge-2-3,
	.column-offset-xlarge-4-6,
	.column-offset-xlarge-6-9,
	.column-offset-xlarge-8-12 {
		margin-left: @column-1-3 * 2;
	}
	
	.column-offset-xlarge-1-4,
	.column-offset-xlarge-2-8,
	.column-offset-xlarge-3-12 {
		margin-left: @column-1-4;
	}
	
	.column-offset-xlarge-3-4,
	.column-offset-xlarge-6-8,
	.column-offset-xlarge-9-12 {
		margin-left: @column-1-4 * 3;
	}
	
	.column-offset-xlarge-1-5,
	.column-offset-xlarge-2-10 {
		margin-left: @column-1-5;
	}
	
	.column-offset-xlarge-2-5,
	.column-offset-xlarge-4-10 {
		margin-left: @column-1-5 * 2;
	}
	
	.column-offset-xlarge-3-5,
	.column-offset-xlarge-6-10 {
		margin-left: @column-1-5 * 3;
	}
	
	.column-offset-xlarge-4-5,
	.column-offset-xlarge-8-10 {
		margin-left: @column-1-5 * 4;
	}
	
	.column-offset-xlarge-1-6,
	.column-offset-xlarge-2-12 {
		margin-left: @column-1-6;
	}
	
	.column-offset-xlarge-5-6 {
		margin-left: @column-1-6 * 5;
	}
	
	.column-offset-xlarge-1-7 {
		margin-left: @column-1-7;
	}
	
	.column-offset-xlarge-2-7 {
		margin-left: @column-1-7 * 2;
	}
	
	.column-offset-xlarge-3-7 {
		margin-left: @column-1-7 * 3;
	}
	
	.column-offset-xlarge-4-7 {
		margin-left: @column-1-7 * 4;
	}
	
	.column-offset-xlarge-5-7 {
		margin-left: @column-1-7 * 5;
	}
	
	.column-offset-xlarge-6-7 {
		margin-left: @column-1-7 * 6;
	}
	
	.column-offset-xlarge-1-8 {
		margin-left: @column-1-8;
	}
	
	.column-offset-xlarge-3-8 {
		margin-left: @column-1-8 * 3;
	}
	
	.column-offset-xlarge-5-8 {
		margin-left: @column-1-8 * 5;
	}
	
	.column-offset-xlarge-7-8 {
		margin-left: @column-1-8 * 7;
	}
	
	.column-offset-xlarge-1-9 {
		margin-left: @column-1-9;
	}
	
	.column-offset-xlarge-2-9 {
		margin-left: @column-1-9 * 2;
	}
	
	.column-offset-xlarge-4-9 {
		margin-left: @column-1-9 * 4;
	}
	
	.column-offset-xlarge-5-9 {
		margin-left: @column-1-9 * 5;
	}
	
	.column-offset-xlarge-7-9 {
		margin-left: @column-1-9 * 7;
	}
	
	.column-offset-xlarge-8-9 {
		margin-left: @column-1-9 * 8;
	}
	
	.column-offset-xlarge-1-10 {
		margin-left: @column-1-10;
	}
	
	.column-offset-xlarge-3-10 {
		margin-left: @column-1-10 * 3;
	}
	
	.column-offset-xlarge-7-10 {
		margin-left: @column-1-10 * 7;
	}
	
	.column-offset-xlarge-9-10 {
		margin-left: @column-1-10 * 9;
	}
	
	.column-offset-xlarge-1-11 {
		margin-left: @column-1-11;
	}
	
	.column-offset-xlarge-2-11 {
		margin-left: @column-1-11 * 2;
	}
	
	.column-offset-xlarge-3-11 {
		margin-left: @column-1-11 * 3;
	}
	
	.column-offset-xlarge-4-11 {
		margin-left: @column-1-11 * 4;
	}
	
	.column-offset-xlarge-5-11 {
		margin-left: @column-1-11 * 5;
	}
	
	.column-offset-xlarge-6-11 {
		margin-left: @column-1-11 * 6;
	}
	
	.column-offset-xlarge-7-11 {
		margin-left: @column-1-11 * 7;
	}
	
	.column-offset-xlarge-8-11 {
		margin-left: @column-1-11 * 8;
	}
	
	.column-offset-xlarge-9-11 {
		margin-left: @column-1-11 * 9;
	}
	
	.column-offset-xlarge-10-11 {
		margin-left: @column-1-11 * 10;
	}
	
	.column-offset-xlarge-1-12 {
		margin-left: @column-1-12;
	}
	
	.column-offset-xlarge-5-12 {
		margin-left: @column-1-12 * 5;
	}
	
	.column-offset-xlarge-7-12 {
		margin-left: @column-1-12 * 7;
	}
	
	.column-offset-xlarge-10-12 {
		margin-left: @column-1-12 * 10;
	}
	
	.column-offset-xlarge-11-12 {
		margin-left: @column-1-12 * 11;
	}
}


/*
 * Column Offset Large Classes
 */
 
@media @large {
	
	//Column Offset Reset
	.column-offset-large-reset {
		margin-left: 0;
	}
	
	.column-offset-large-1 {
		margin-left: @column-1;
	}
	
	.column-offset-large-1-2,
	.column-offset-large-2-4,
	.column-offset-large-3-6,
	.column-offset-large-4-8,
	.column-offset-large-5-10,
	.column-offset-large-6-12  {
		margin-left: @column-1-2;
	}
	
	.column-offset-large-1-3,
	.column-offset-large-2-6,
	.column-offset-large-3-9,
	.column-offset-large-4-12 {
		margin-left: @column-1-3;
	}
	
	.column-offset-large-2-3,
	.column-offset-large-4-6,
	.column-offset-large-6-9,
	.column-offset-large-8-12 {
		margin-left: @column-1-3 * 2;
	}
	
	.column-offset-large-1-4,
	.column-offset-large-2-8,
	.column-offset-large-3-12 {
		margin-left: @column-1-4;
	}
	
	.column-offset-large-3-4,
	.column-offset-large-6-8,
	.column-offset-large-9-12 {
		margin-left: @column-1-4 * 3;
	}
	
	.column-offset-large-1-5,
	.column-offset-large-2-10 {
		margin-left: @column-1-5;
	}
	
	.column-offset-large-2-5,
	.column-offset-large-4-10 {
		margin-left: @column-1-5 * 2;
	}
	
	.column-offset-large-3-5,
	.column-offset-large-6-10 {
		margin-left: @column-1-5 * 3;
	}
	
	.column-offset-large-4-5,
	.column-offset-large-8-10 {
		margin-left: @column-1-5 * 4;
	}
	
	.column-offset-large-1-6,
	.column-offset-large-2-12 {
		margin-left: @column-1-6;
	}
	
	.column-offset-large-5-6 {
		margin-left: @column-1-6 * 5;
	}
	
	.column-offset-large-1-7 {
		margin-left: @column-1-7;
	}
	
	.column-offset-large-2-7 {
		margin-left: @column-1-7 * 2;
	}
	
	.column-offset-large-3-7 {
		margin-left: @column-1-7 * 3;
	}
	
	.column-offset-large-4-7 {
		margin-left: @column-1-7 * 4;
	}
	
	.column-offset-large-5-7 {
		margin-left: @column-1-7 * 5;
	}
	
	.column-offset-large-6-7 {
		margin-left: @column-1-7 * 6;
	}
	
	.column-offset-large-1-8 {
		margin-left: @column-1-8;
	}
	
	.column-offset-large-3-8 {
		margin-left: @column-1-8 * 3;
	}
	
	.column-offset-large-5-8 {
		margin-left: @column-1-8 * 5;
	}
	
	.column-offset-large-7-8 {
		margin-left: @column-1-8 * 7;
	}
	
	.column-offset-large-1-9 {
		margin-left: @column-1-9;
	}
	
	.column-offset-large-2-9 {
		margin-left: @column-1-9 * 2;
	}
	
	.column-offset-large-4-9 {
		margin-left: @column-1-9 * 4;
	}
	
	.column-offset-large-5-9 {
		margin-left: @column-1-9 * 5;
	}
	
	.column-offset-large-7-9 {
		margin-left: @column-1-9 * 7;
	}
	
	.column-offset-large-8-9 {
		margin-left: @column-1-9 * 8;
	}
	
	.column-offset-large-1-10 {
		margin-left: @column-1-10;
	}
	
	.column-offset-large-3-10 {
		margin-left: @column-1-10 * 3;
	}
	
	.column-offset-large-7-10 {
		margin-left: @column-1-10 * 7;
	}
	
	.column-offset-large-9-10 {
		margin-left: @column-1-10 * 9;
	}
	
	.column-offset-large-1-11 {
		margin-left: @column-1-11;
	}
	
	.column-offset-large-2-11 {
		margin-left: @column-1-11 * 2;
	}
	
	.column-offset-large-3-11 {
		margin-left: @column-1-11 * 3;
	}
	
	.column-offset-large-4-11 {
		margin-left: @column-1-11 * 4;
	}
	
	.column-offset-large-5-11 {
		margin-left: @column-1-11 * 5;
	}
	
	.column-offset-large-6-11 {
		margin-left: @column-1-11 * 6;
	}
	
	.column-offset-large-7-11 {
		margin-left: @column-1-11 * 7;
	}
	
	.column-offset-large-8-11 {
		margin-left: @column-1-11 * 8;
	}
	
	.column-offset-large-9-11 {
		margin-left: @column-1-11 * 9;
	}
	
	.column-offset-large-10-11 {
		margin-left: @column-1-11 * 10;
	}
	
	.column-offset-large-1-12 {
		margin-left: @column-1-12;
	}
	
	.column-offset-large-5-12 {
		margin-left: @column-1-12 * 5;
	}
	
	.column-offset-large-7-12 {
		margin-left: @column-1-12 * 7;
	}
	
	.column-offset-large-10-12 {
		margin-left: @column-1-12 * 10;
	}
	
	.column-offset-large-11-12 {
		margin-left: @column-1-12 * 11;
	}
}


/*
 * Column Offset Medium Classes
 */
 
@media @medium {

	//Column Offset Reset
	.column-offset-medium-reset {
		margin-left: 0;
	}

	.column-offset-medium-1 {
		margin-left: @column-1;
	}
	
	.column-offset-medium-1-2,
	.column-offset-medium-2-4,
	.column-offset-medium-3-6,
	.column-offset-medium-4-8,
	.column-offset-medium-5-10,
	.column-offset-medium-6-12  {
		margin-left: @column-1-2;
	}
	
	.column-offset-medium-1-3,
	.column-offset-medium-2-6,
	.column-offset-medium-3-9,
	.column-offset-medium-4-12 {
		margin-left: @column-1-3;
	}
	
	.column-offset-medium-2-3,
	.column-offset-medium-4-6,
	.column-offset-medium-6-9,
	.column-offset-medium-8-12 {
		margin-left: @column-1-3 * 2;
	}
	
	.column-offset-medium-1-4,
	.column-offset-medium-2-8,
	.column-offset-medium-3-12 {
		margin-left: @column-1-4;
	}
	
	.column-offset-medium-3-4,
	.column-offset-medium-6-8,
	.column-offset-medium-9-12 {
		margin-left: @column-1-4 * 3;
	}
	
	.column-offset-medium-1-5,
	.column-offset-medium-2-10 {
		margin-left: @column-1-5;
	}
	
	.column-offset-medium-2-5,
	.column-offset-medium-4-10 {
		margin-left: @column-1-5 * 2;
	}
	
	.column-offset-medium-3-5,
	.column-offset-medium-6-10 {
		margin-left: @column-1-5 * 3;
	}
	
	.column-offset-medium-4-5,
	.column-offset-medium-8-10 {
		margin-left: @column-1-5 * 4;
	}
	
	.column-offset-medium-1-6,
	.column-offset-medium-2-12 {
		margin-left: @column-1-6;
	}
	
	.column-offset-medium-5-6 {
		margin-left: @column-1-6 * 5;
	}
	
	.column-offset-medium-1-7 {
		margin-left: @column-1-7;
	}
	
	.column-offset-medium-2-7 {
		margin-left: @column-1-7 * 2;
	}
	
	.column-offset-medium-3-7 {
		margin-left: @column-1-7 * 3;
	}
	
	.column-offset-medium-4-7 {
		margin-left: @column-1-7 * 4;
	}
	
	.column-offset-medium-5-7 {
		margin-left: @column-1-7 * 5;
	}
	
	.column-offset-medium-6-7 {
		margin-left: @column-1-7 * 6;
	}
	
	.column-offset-medium-1-8 {
		margin-left: @column-1-8;
	}
	
	.column-offset-medium-3-8 {
		margin-left: @column-1-8 * 3;
	}
	
	.column-offset-medium-5-8 {
		margin-left: @column-1-8 * 5;
	}
	
	.column-offset-medium-7-8 {
		margin-left: @column-1-8 * 7;
	}
	
	.column-offset-medium-1-9 {
		margin-left: @column-1-9;
	}
	
	.column-offset-medium-2-9 {
		margin-left: @column-1-9 * 2;
	}
	
	.column-offset-medium-4-9 {
		margin-left: @column-1-9 * 4;
	}
	
	.column-offset-medium-5-9 {
		margin-left: @column-1-9 * 5;
	}
	
	.column-offset-medium-7-9 {
		margin-left: @column-1-9 * 7;
	}
	
	.column-offset-medium-8-9 {
		margin-left: @column-1-9 * 8;
	}
	
	.column-offset-medium-1-10 {
		margin-left: @column-1-10;
	}
	
	.column-offset-medium-3-10 {
		margin-left: @column-1-10 * 3;
	}
	
	.column-offset-medium-7-10 {
		margin-left: @column-1-10 * 7;
	}
	
	.column-offset-medium-9-10 {
		margin-left: @column-1-10 * 9;
	}
	
	.column-offset-medium-1-11 {
		margin-left: @column-1-11;
	}
	
	.column-offset-medium-2-11 {
		margin-left: @column-1-11 * 2;
	}
	
	.column-offset-medium-3-11 {
		margin-left: @column-1-11 * 3;
	}
	
	.column-offset-medium-4-11 {
		margin-left: @column-1-11 * 4;
	}
	
	.column-offset-medium-5-11 {
		margin-left: @column-1-11 * 5;
	}
	
	.column-offset-medium-6-11 {
		margin-left: @column-1-11 * 6;
	}
	
	.column-offset-medium-7-11 {
		margin-left: @column-1-11 * 7;
	}
	
	.column-offset-medium-8-11 {
		margin-left: @column-1-11 * 8;
	}
	
	.column-offset-medium-9-11 {
		margin-left: @column-1-11 * 9;
	}
	
	.column-offset-medium-10-11 {
		margin-left: @column-1-11 * 10;
	}
	
	.column-offset-medium-1-12 {
		margin-left: @column-1-12;
	}
	
	.column-offset-medium-5-12 {
		margin-left: @column-1-12 * 5;
	}
	
	.column-offset-medium-7-12 {
		margin-left: @column-1-12 * 7;
	}
	
	.column-offset-medium-10-12 {
		margin-left: @column-1-12 * 10;
	}
	
	.column-offset-medium-11-12 {
		margin-left: @column-1-12 * 11;
	}
}


/*
 * Column Offset Small Classes
 */
 
@media @small {

	.column-offset-small-1 {
		margin-left: @column-1;
	}
	
	.column-offset-small-1-2,
	.column-offset-small-2-4,
	.column-offset-small-3-6,
	.column-offset-small-4-8,
	.column-offset-small-5-10,
	.column-offset-small-6-12  {
		margin-left: @column-1-2;
	}
	
	.column-offset-small-1-3,
	.column-offset-small-2-6,
	.column-offset-small-3-9,
	.column-offset-small-4-12 {
		margin-left: @column-1-3;
	}
	
	.column-offset-small-2-3,
	.column-offset-small-4-6,
	.column-offset-small-6-9,
	.column-offset-small-8-12 {
		margin-left: @column-1-3 * 2;
	}
	
	.column-offset-small-1-4,
	.column-offset-small-2-8,
	.column-offset-small-3-12 {
		margin-left: @column-1-4;
	}
	
	.column-offset-small-3-4,
	.column-offset-small-6-8,
	.column-offset-small-9-12 {
		margin-left: @column-1-4 * 3;
	}
	
	.column-offset-small-1-5,
	.column-offset-small-2-10 {
		margin-left: @column-1-5;
	}
	
	.column-offset-small-2-5,
	.column-offset-small-4-10 {
		margin-left: @column-1-5 * 2;
	}
	
	.column-offset-small-3-5,
	.column-offset-small-6-10 {
		margin-left: @column-1-5 * 3;
	}
	
	.column-offset-small-4-5,
	.column-offset-small-8-10 {
		margin-left: @column-1-5 * 4;
	}
	
	.column-offset-small-1-6,
	.column-offset-small-2-12 {
		margin-left: @column-1-6;
	}
	
	.column-offset-small-5-6 {
		margin-left: @column-1-6 * 5;
	}
	
	.column-offset-small-1-7 {
		margin-left: @column-1-7;
	}
	
	.column-offset-small-2-7 {
		margin-left: @column-1-7 * 2;
	}
	
	.column-offset-small-3-7 {
		margin-left: @column-1-7 * 3;
	}
	
	.column-offset-small-4-7 {
		margin-left: @column-1-7 * 4;
	}
	
	.column-offset-small-5-7 {
		margin-left: @column-1-7 * 5;
	}
	
	.column-offset-small-6-7 {
		margin-left: @column-1-7 * 6;
	}
	
	.column-offset-small-1-8 {
		margin-left: @column-1-8;
	}
	
	.column-offset-small-3-8 {
		margin-left: @column-1-8 * 3;
	}
	
	.column-offset-small-5-8 {
		margin-left: @column-1-8 * 5;
	}
	
	.column-offset-small-7-8 {
		margin-left: @column-1-8 * 7;
	}
	
	.column-offset-small-1-9 {
		margin-left: @column-1-9;
	}
	
	.column-offset-small-2-9 {
		margin-left: @column-1-9 * 2;
	}
	
	.column-offset-small-4-9 {
		margin-left: @column-1-9 * 4;
	}
	
	.column-offset-small-5-9 {
		margin-left: @column-1-9 * 5;
	}
	
	.column-offset-small-7-9 {
		margin-left: @column-1-9 * 7;
	}
	
	.column-offset-small-8-9 {
		margin-left: @column-1-9 * 8;
	}
	
	.column-offset-small-1-10 {
		margin-left: @column-1-10;
	}
	
	.column-offset-small-3-10 {
		margin-left: @column-1-10 * 3;
	}
	
	.column-offset-small-7-10 {
		margin-left: @column-1-10 * 7;
	}
	
	.column-offset-small-9-10 {
		margin-left: @column-1-10 * 9;
	}
	
	.column-offset-small-1-11 {
		margin-left: @column-1-11;
	}
	
	.column-offset-small-2-11 {
		margin-left: @column-1-11 * 2;
	}
	
	.column-offset-small-3-11 {
		margin-left: @column-1-11 * 3;
	}
	
	.column-offset-small-4-11 {
		margin-left: @column-1-11 * 4;
	}
	
	.column-offset-small-5-11 {
		margin-left: @column-1-11 * 5;
	}
	
	.column-offset-small-6-11 {
		margin-left: @column-1-11 * 6;
	}
	
	.column-offset-small-7-11 {
		margin-left: @column-1-11 * 7;
	}
	
	.column-offset-small-8-11 {
		margin-left: @column-1-11 * 8;
	}
	
	.column-offset-small-9-11 {
		margin-left: @column-1-11 * 9;
	}
	
	.column-offset-small-10-11 {
		margin-left: @column-1-11 * 10;
	}
	
	.column-offset-small-1-12 {
		margin-left: @column-1-12;
	}
	
	.column-offset-small-5-12 {
		margin-left: @column-1-12 * 5;
	}
	
	.column-offset-small-7-12 {
		margin-left: @column-1-12 * 7;
	}
	
	.column-offset-small-10-12 {
		margin-left: @column-1-12 * 10;
	}
	
	.column-offset-small-11-12 {
		margin-left: @column-1-12 * 11;
	}
}

/*
 * Column Offset Extra Small Classes
 */
 
@media @xsmall {
	
	[class*='column-offset-'] {
		margin-left: 0;
	}
	
	.column-offset-xsmall-1 {
		margin-left: @column-1;
	}
	
	.column-offset-xsmall-1-2,
	.column-offset-xsmall-2-4,
	.column-offset-xsmall-3-6,
	.column-offset-xsmall-4-8,
	.column-offset-xsmall-5-10,
	.column-offset-xsmall-6-12  {
		margin-left: @column-1-2;
	}
	
	.column-offset-xsmall-1-3,
	.column-offset-xsmall-2-6,
	.column-offset-xsmall-3-9,
	.column-offset-xsmall-4-12 {
		margin-left: @column-1-3;
	}
	
	.column-offset-xsmall-2-3,
	.column-offset-xsmall-4-6,
	.column-offset-xsmall-6-9,
	.column-offset-xsmall-8-12 {
		margin-left: @column-1-3 * 2;
	}
	
	.column-offset-xsmall-1-4,
	.column-offset-xsmall-2-8,
	.column-offset-xsmall-3-12 {
		margin-left: @column-1-4;
	}
	
	.column-offset-xsmall-3-4,
	.column-offset-xsmall-6-8,
	.column-offset-xsmall-9-12 {
		margin-left: @column-1-4 * 3;
	}
	
	.column-offset-xsmall-1-5,
	.column-offset-xsmall-2-10 {
		margin-left: @column-1-5;
	}
	
	.column-offset-xsmall-2-5,
	.column-offset-xsmall-4-10 {
		margin-left: @column-1-5 * 2;
	}
	
	.column-offset-xsmall-3-5,
	.column-offset-xsmall-6-10 {
		margin-left: @column-1-5 * 3;
	}
	
	.column-offset-xsmall-4-5,
	.column-offset-xsmall-8-10 {
		margin-left: @column-1-5 * 4;
	}
	
	.column-offset-xsmall-1-6,
	.column-offset-xsmall-2-12 {
		margin-left: @column-1-6;
	}
	
	.column-offset-xsmall-5-6 {
		margin-left: @column-1-6 * 5;
	}
	
	.column-offset-xsmall-1-7 {
		margin-left: @column-1-7;
	}
	
	.column-offset-xsmall-2-7 {
		margin-left: @column-1-7 * 2;
	}
	
	.column-offset-xsmall-3-7 {
		margin-left: @column-1-7 * 3;
	}
	
	.column-offset-xsmall-4-7 {
		margin-left: @column-1-7 * 4;
	}
	
	.column-offset-xsmall-5-7 {
		margin-left: @column-1-7 * 5;
	}
	
	.column-offset-xsmall-6-7 {
		margin-left: @column-1-7 * 6;
	}
	
	.column-offset-xsmall-1-8 {
		margin-left: @column-1-8;
	}
	
	.column-offset-xsmall-3-8 {
		margin-left: @column-1-8 * 3;
	}
	
	.column-offset-xsmall-5-8 {
		margin-left: @column-1-8 * 5;
	}
	
	.column-offset-xsmall-7-8 {
		margin-left: @column-1-8 * 7;
	}
	
	.column-offset-xsmall-1-9 {
		margin-left: @column-1-9;
	}
	
	.column-offset-xsmall-2-9 {
		margin-left: @column-1-9 * 2;
	}
	
	.column-offset-xsmall-4-9 {
		margin-left: @column-1-9 * 4;
	}
	
	.column-offset-xsmall-5-9 {
		margin-left: @column-1-9 * 5;
	}
	
	.column-offset-xsmall-7-9 {
		margin-left: @column-1-9 * 7;
	}
	
	.column-offset-xsmall-8-9 {
		margin-left: @column-1-9 * 8;
	}
	
	.column-offset-xsmall-1-10 {
		margin-left: @column-1-10;
	}
	
	.column-offset-xsmall-3-10 {
		margin-left: @column-1-10 * 3;
	}
	
	.column-offset-xsmall-7-10 {
		margin-left: @column-1-10 * 7;
	}
	
	.column-offset-xsmall-9-10 {
		margin-left: @column-1-10 * 9;
	}
	
	.column-offset-xsmall-1-11 {
		margin-left: @column-1-11;
	}
	
	.column-offset-xsmall-2-11 {
		margin-left: @column-1-11 * 2;
	}
	
	.column-offset-xsmall-3-11 {
		margin-left: @column-1-11 * 3;
	}
	
	.column-offset-xsmall-4-11 {
		margin-left: @column-1-11 * 4;
	}
	
	.column-offset-xsmall-5-11 {
		margin-left: @column-1-11 * 5;
	}
	
	.column-offset-xsmall-6-11 {
		margin-left: @column-1-11 * 6;
	}
	
	.column-offset-xsmall-7-11 {
		margin-left: @column-1-11 * 7;
	}
	
	.column-offset-xsmall-8-11 {
		margin-left: @column-1-11 * 8;
	}
	
	.column-offset-xsmall-9-11 {
		margin-left: @column-1-11 * 9;
	}
	
	.column-offset-xsmall-10-11 {
		margin-left: @column-1-11 * 10;
	}
	
	.column-offset-xsmall-1-12 {
		margin-left: @column-1-12;
	}
	
	.column-offset-xsmall-5-12 {
		margin-left: @column-1-12 * 5;
	}
	
	.column-offset-xsmall-7-12 {
		margin-left: @column-1-12 * 7;
	}
	
	.column-offset-xsmall-10-12 {
		margin-left: @column-1-12 * 10;
	}
	
	.column-offset-xsmall-11-12 {
		margin-left: @column-1-12 * 11;
	}
}