/*
 * Definitions
 */

// Emphasis
@em-font-style: italic;


/*
 * Emphasis
 */

em {
	font-style: @em-font-style;
}