/*
 * Definitions
 */

// Code
@code-font-family: @monospace-font-family;
@code-font-weight: normal;
@code-font-size: 1.2;
@code-color: @tertiary;


/*
 * Code
 */

code {
	font-family: @code-font-family;
	font-weight: @code-font-weight;
	.font-size(@code-font-size);
	color: @code-color;
}