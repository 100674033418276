/*
 * Font Family
 */

// Sans Serif Font
.sans-serif-font {
	font-family: @sans-serif-font-family;
}

// Serif Font
.serif-font {
	font-family: @serif-font-family;
}

// Monospace Font
.monospace-font {
	font-family: @monospace-font-family;
}

// Thin Font
.thin-font {
	.thin-font();
}

// Regular Font
.regular-font {
	.regular-font();
}

// Medium Font
.medium-font {
	.medium-font();
}

// Semibold Font
.semibold-font {
	.semibold-font();
}

// Bold Font
.bold-font {
	.bold-font();
}
