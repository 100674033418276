/*
 * Max Width
 */

.max-width-xlarge {
	max-width: @max-width-xlarge;
}

.max-width-large {
	max-width: @max-width-large;
}

.max-width-medium {
	max-width: @max-width-medium;
}

.max-width-small {
	max-width: @max-width-small;
}

.max-width-xsmall {
	max-width: @max-width-xsmall;
}