/*
 * Text Color
 */

//Shades
.text-white {
	color: @white !important;
}

.text-light-grey {
	color: @light-grey  !important;
}

.text-grey {
	color: @grey !important;
}

.text-dark-grey {
	color: @dark-grey !important;
}

.text-black {
	color: @black !important;
}

//Indicators
.text-error {
	color: @error !important;
}

.text-warning {
	color: @warning !important;
}

.text-success {
	color: @success !important;
}

.text-info {
	color: @info !important;
}

//Palette
.text-primary {
	color: @primary !important;
}

.text-secondary {
	color: @secondary !important;
}

.text-tertiary {
	color: @tertiary !important;
}

.text-dark {
	color: @dark !important;
}

.text-light {
	color: @light !important;
}