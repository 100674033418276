/*
 * Definitions
 */

// Button
@button-padding: 12px 16px;
@button-height: @height-medium;
@button-font-family: @bold-font-family;
@button-font-weight: @bold-font-weight;
@button-font-size: 1.4;
@button-color: @dark-grey;
@button-line-height: 1;
@button-text-align: center;
@button-text-transform: none;
@button-background: @white;
@button-border: 1px solid @light-grey;
@button-border-radius: @border-radius;

// Button Hover
@button-hover-color: @dark-grey;
@button-hover-background: darken(@button-background, 10%);
@button-hover-border: 1px solid darken(@light-grey, 10%);

// Button Disabled
@button-disabled-opacity: 0.5;

// Button Block
@button-block-text-align: center;


/*
 * Button
 */

// Button
button,
.button {
	.button();
	.button-size(@button-padding, @button-height, @button-font-size);
	.button-theme(@button-color, @button-background, @button-border, @button-hover-color, @button-hover-background, @button-hover-border);
}

// Button Disabled
button:disabled {
	cursor: no-drop;
	opacity: @button-disabled-opacity;
}

// iOS "clickable elements" fix for role="button"
[role="button"] {
	cursor: pointer;
}
	
/*
 * Button Modifiers
 */

// Button Block
.button-block {
	display: block;
	width: 100%;
	text-align: @button-block-text-align;
}

// Button Center
.button-center {
	display: inline-block;
	margin: 0 auto;
}