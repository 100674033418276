/*
 * Transformations
 */

.transform(@transform) {
	-webkit-transform: @transform;
	-ms-transform: @transform;
	transform: @transform;
}

.rotate(@degrees) {
	-webkit-transform: rotate(@degrees);
	-ms-transform: rotate(@degrees);
	transform: rotate(@degrees);
}

.scale(@ratio) {
	-webkit-transform: scale(@ratio);
	-ms-transform: scale(@ratio);
	transform: scale(@ratio);
}

.scale3d(@x, @y, @z) {
	-webkit-transform: scale3d(@x, @y, @z);
	-ms-transform: scale3d(@x, @y, @z);
	transform: scale3d(@x, @y, @z);
}

.translate(@x, @y) {
	-webkit-transform: translate(@x, @y);
	-ms-transform: translate(@x, @y);
	transform: translate(@x, @y);
}

.skew(@x, @y) {
	-webkit-transform: skew(@x, @y);
	-ms-transform: skewX(@x) skewY(@y);
	transform: skew(@x, @y);
}

.translate3d(@x, @y, @z) {
	-webkit-transform: translate3d(@x, @y, @z);
	transform: translate3d(@x, @y, @z);
}

.translateX(@offset) {
	-webkit-transform: translateX(@offset);
	-ms-transform: translateX(@offset);
	transform: translateX(@offset);
}

.translateY(@offset) {
	-webkit-transform: translateY(@offset);
	-ms-transform: translateY(@offset);
	transform: translateY(@offset);
}

.translateZ(@offset) {
	-webkit-transform: translateZ(@offset);
	-ms-transform: translateZ(@offset);
	transform: translateZ(@offset);
}

.rotateX(@degrees) {
	-webkit-transform: rotateX(@degrees);
	-ms-transform: rotateX(@degrees);
	transform: rotateX(@degrees);
}

.rotateY(@degrees) {
	-webkit-transform: rotateY(@degrees);
	-ms-transform: rotateY(@degrees);
	transform: rotateY(@degrees);
}

.transform-origin(@origin) {
	-webkit-transform-origin: @origin;
	-ms-transform-origin: @origin;
	transform-origin: @origin;
}

.transform-style(@style) {
	-webkit-transform-style: @style;
	-ms-transform-style: @style;
	transform-style: @style;
}

.perspective(@perspective) {
	-webkit-perspective: @perspective;
	perspective: @perspective;
}

.perspective-origin(@perspective) {
	-webkit-perspective-origin: @perspective;
	perspective-origin: @perspective;
}

.backface-visibility(@visibility) {
	-webkit-backface-visibility: @visibility;
	backface-visibility: @visibility;
}