/*
 * Definitions
 */

// Headings
@headings-margin: 0px 0px 15px 0px;
@headings-margin-large: 0px 0px 25px 0px;
@headings-margin-xsmall: 0px 0px 10px 0px;
@headings-font-family: @medium-font-family;
@headings-font-weight: @medium-font-weight;
@headings-color: @very-dark;
@headings-line-height: @line-height-small;
@heading-border-color: @grey;
@heading-border-color-light: @light-grey;

// Headings Small
@headings-small-font-family: @medium-font-family;
@headings-small-font-weight: @medium-font-weight;
@headings-small-size: 0.6em;
@headings-small-color: @grey;

// Heading Font Sizes
@h1-font-size: 2.5;
@h2-font-size: 2.05263;
@h3-font-size: 1.73684;
@h4-font-size: 1.57895;
@h5-font-size: 1.42105;
@h6-font-size: 1.1;


/*
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: @headings-margin;
	padding: 0;
	font-family: @headings-font-family;
	font-weight: @headings-font-weight;
	color: @headings-color;
	line-height: @headings-line-height;
  &.border-bottom-title{
    padding-bottom: .5em;
    margin: @headings-margin-large;
    border-bottom: 1px solid @heading-border-color-light;
  }
  &.border-left-title{
    padding-top: .3em;
    padding-bottom: .3em;
    padding-left: .5em;
    margin: @headings-margin-large;
    border-left: 5px solid @heading-border-color;
  }
}

body.body-black &{
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: @white;
  }
}

// Heading Small
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
	font-family: @headings-small-font-family;
	font-weight: @headings-small-font-weight;
	font-size: @headings-small-size;
	color: @headings-small-color;
}

// Heading Font Sizes
h1 {
	.font-size(@h1-font-size);
}

h2 {
	.font-size(@h2-font-size);
}

h3 {
	.font-size(@h3-font-size);
}

h4 {
	.font-size(@h4-font-size);
}

h5 {
	.font-size(@h5-font-size);
}

h6 {
	.font-size(@h6-font-size);
}